import React from 'react'

const TermsConditions = () => {
  return (
    <div>
      There is no data that is stored in the application. All data will only be used for marketing purposes for the application and will be removed upon request.
    </div>
  )
}

export default TermsConditions;