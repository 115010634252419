// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .sideBar {
  margin: 0;
  width: 20%;
  height: 100%;
  background-color: #eee;
}
.content {
  top: 0;
  width: 80%;
}

.layout {
  display: flex;
  flex-direction: row;
  flex: auto;
} */
`, "",{"version":3,"sources":["webpack://./src/components/sidebar.component.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;GAeG","sourcesContent":["/* .sideBar {\n  margin: 0;\n  width: 20%;\n  height: 100%;\n  background-color: #eee;\n}\n.content {\n  top: 0;\n  width: 80%;\n}\n\n.layout {\n  display: flex;\n  flex-direction: row;\n  flex: auto;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
