import React from "react";
import { View, Text, XStack, YStack } from "tamagui";

const Home = () => {
  return (
    <YStack margin={10}>

      <Text color='#000' m={0} alignSelf={'center'} scale={2.5} className={'header'}>
        Welcome to Jellyo
      </Text>

    </YStack>

  );
}

export default Home;