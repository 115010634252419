import "./App.css";
import ReactDOM from "react-dom/client";
import '@tamagui/core/reset.css'
import { TamaguiProvider, createTamagui, View } from "@tamagui/core";
import config from './tamagui.config'

import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";

import TermsConditions from "./pages/terms-conditions.page";
import Home from "./pages/home.page";
import React, { Component } from "react";
import SideBar from "./components/sidebar.component";
import { XStack } from "tamagui";


const App = () => {
  return (
    <TamaguiProvider config={config}>
      <Router>
        <XStack>
          <SideBar />

          <Routes>
            <Route index element={<Home />} />
            <Route path="/terms-and-conditions" element={<TermsConditions />} />
            {/* <Route path="/blog" element={<Blog />} /> */}
          </Routes>
        </XStack>
      </Router>

    </TamaguiProvider>
  )
}

export default App