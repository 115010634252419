import React from 'react'
import { Link, Outlet } from "react-router-dom";
import "./sidebar.component.css";
import { View, YStack, XStack, Nav, Text, ListItem, YGroup } from 'tamagui';

const SideBar = () => {
  return (
    <XStack space className="layout">
      <YStack className="sideBar" maxWidth={'25vw'} margin="$0" padding="$2" alignSelf='center'>
        <Text className={'header'} scale={2.5}>
          Jellyo
          </Text>
        <Nav>
          <YGroup>
            <ListItem>
              <Link to="/">Home</Link>
            </ListItem>
            <ListItem>
              <Link to="/terms-and-conditions">Terms and Conditions</Link>
            </ListItem>
          </YGroup>
        </Nav>
      </YStack>
      {/* <YStack className="content">
          <Outlet />
        </YStack> */}
    </XStack>
  );
}

export default SideBar;
